import React from 'react';
import './App.css';
import type {Node} from 'react';
import { writeContract } from '@wagmi/core';
import { watchContractEvent } from '@wagmi/core';
import { readContract } from '@wagmi/core';
import { Icon } from '@iconify/react';
import { configureChains, createConfig, WagmiConfig, useAccount, useNetwork } from 'wagmi'; 
import { publicProvider } from 'wagmi/providers/public'; import { disconnect } from '@wagmi/core';
import { getDefaultWallets, RainbowKitProvider, useConnectModal, useChainModal } from '@rainbow-me/rainbowkit'; import '@rainbow-me/rainbowkit/styles.css';
import YachtingVerse from './YachtingVerse.json';
import Nft_YSTAKE from './Nft_YSTAKE.json';
import Staking from './Staking.json';

function makeADecimalTextIntoLongText(decimalText, digits){
		var locOfDot = decimalText.indexOf('.');
		if (locOfDot === -1){
				return decimalText + makeDigits(digits);
		}else{
				var before = decimalText.substr(0, locOfDot);
				var after = decimalText.substr(locOfDot + 1);
				if (after.length > digits){
						return before + after.substr(0, digits);      
				}else{
						return before + after + makeDigits(digits - after.length);
				}
		}
}

function makeDigits(digits){
		var x = '';
		for (var i = 0; i < digits; i++){
				x += '0';
		}
		return x;
}

function getValueFromExistingSmartContract(account, address, jsonFile, functionName, inputTypeList, outputTypeList, chainInfo, setChainInfo, updateChecks, ...argsIn){
	
	var defaultSlate = {};

	function coverValueIfNecc(type, value){
		if (type.t === 'ListType'){
			return value.map((aVal, index)=>{
				return coverValueIfNecc(type.c, aVal);
			})
		}else if (type.t === 'Object'){
			var p = {};
			type.c.forEach((aC, index)=>{
				var cc = coverValueIfNecc(aC, value[aC.n]);
				p[aC.n] = cc;
			})
			return p;
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			if (!value.hex){
	  			return BigInt(value);
			}
		}else if (type.t === 'Text String'){
			return value.split('.infura').join('');
		}
		return value;
	}

	function flattenType(inputType, aI){
		if (inputType.t === 'ListType'){
			return aI.map((anInput, index)=>{
				return flattenType(inputType.c, anInput);
			}).join(', ');
		}else if (inputType.t === 'UInteger' || inputType.t === 'Integer'){
			return aI.toString();
		}else if (inputType.t === 'Boolean'){
			if (aI){
				return 'true'
			}else{
				return 'false'
			}
		}else if (inputType.t === 'Object'){
			var cc = {};
			inputType.c.forEach((anInput, index)=>{
				var p = flattenType(anInput, aI[anInput.n]);
				cc[anInput.n] = p;
			})
			return JSON.stringify(cc);
		}else if (inputType.t === 'Bytes'){
			return '_';
		}else if (inputType.t === 'Text String' || inputType.t === 'Address'){
			return aI;
		}else{
			console.warn(inputType);
			return aI;
		}
	}

	if (account){

		var args = argsIn.filter((aI, index)=>{
			return index < inputTypeList.length;
		})

		var flattenedInputs = args.map((aI, index)=>{
			var inputType = inputTypeList[+index];
			return flattenType(inputType, aI);
		})

		var point = [address, functionName].concat(flattenedInputs);
		var pOut = layoutFoundationForAnObject(point, chainInfo);
		if (pOut[0] !== undefined){
			return pOut;
		}else{

			function actuallyCheck(){
				var gotNotChecked = false;
				for (var i = 0; i < updateChecks.length; i++){
					if (!updateChecks[i]){
						gotNotChecked = true;
						break;
					}
				}
				if (gotNotChecked){
					setTimeout(function(e){ actuallyCheck(); }, 500);
				}else{

			        var stuff = {
			          address,
			          abi: jsonFile,
			          args,
			          functionName,
			        };

			        readContract(stuff).then((value)=>{
						var k = {checked:true}
						if (outputTypeList.length === 1){
							k[0] = coverValueIfNecc(outputTypeList[0] , value);
						}else{
							for (var i = 0; i < outputTypeList.length; i++){
								var aVal = coverValueIfNecc(outputTypeList[i], value[i]);
								k[i] = aVal;
							}
						}
						replacement(point, chainInfo, k);
						setChainInfo({...chainInfo});
			        }).catch((e)=>{
						console.log(e);
			        });

				}
			}

			actuallyCheck();
			return defaultSlate;
		}
	}else{
		return defaultSlate;
	}
}

function defaultValue(type, path){
	for (var i = 0; i < path.length; i++){
		if (path[i].t === 'l'){
			type = type.c;
		}else if (path[i].t === 'oP'){
			for (var j = 0; j < type.c.length; j++){
				if (type.c[j].n === path[i].v){
					type = type.c[j].t;
					break;
				}
			}
		}
	}

	function processDefault(type){
		if (type.t === 'ListType'){
			return [];
		}else if (type.t === 'Object'){
			var out = {};
			for (var i = 0; i < type.c.length; i++){
				out[type.c[i].n] = processDefault(type.c[i].t);
			}
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			return BigInt('0');
		}else if (type.t === 'Text String'){
			return '-';
		}else if (type.t === 'Address'){
			return '0x0000000000000000000000000000000000000000'
		}else if (type.t === 'Boolean'){
			return false;
		}
	}
	return processDefault(type);
}

function getInfoFromTokenURI(uri, otherInfo, setOtherInfo, pointer){

	var reference = pointer + '_' + uri;
	if (setOtherInfo){
		if (otherInfo[reference]){
			if (otherInfo[reference].loaded){
				return otherInfo[reference].v;
			}
		}else{
	        if (uri.indexOf('ipfs://') === 0){
	          uri = 'https://ipfs.io/ipfs/' + uri.substr(7);
	        }

			fetch(uri)
				.then(function(response){
					return response.json();
				})
				.then(function(json){
					var val = json[pointer].split('.infura').join('');
			        if (val.indexOf('ipfs://') === 0){
			          val = 'https://ipfs.io/ipfs/' + val.substr(7);
			        }
		            setOtherInfo((otherInfo) => ({...otherInfo, [reference]:{loaded:1, v:val}}));
				})
				.catch(error => {
					var message = error.message;
		            setTimeout(function(){ 
		              delete otherInfo[reference];
		              setOtherInfo({...otherInfo});
		            }, 1000);
					console.log(message);
				});
		        setOtherInfo((otherInfo) => ({...otherInfo, [reference]:{loading:1}}));
		}

	}
	return '';	
}

function cleanUpSrc(thisImg){
		if (thisImg.indexOf('ipfs://') === 0){
				thisImg = 'https://ipfs.io/ipfs/' + thisImg.substr(7);
		}
		return thisImg;
}

function listFromTwoUIntegers(a, b){
		if (a <= b){
				var init = Number(a);
				var ender = Number(b);
				var out = [];
				for (var i10 = init; i10 <= ender; i10++ ){
						out.push(BigInt(i10));
				}
				return out;
		}else{
				return [];
		}
}

function shortenName(text){
				if (text.length < 9){
								return text;
				}    
				return text.substr(0, 2) + '...' + text.substr(text.length - 4);
}

function layoutFoundationForAnObject(list, chainInfo){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		var p1 = p[list[i]];
		if (!p1){
			p[list[i]] = {};
			p1 = p[list[i]];
		}
		p = p1;
	}
	return p;
}

function replacement(list, chainInfo, object){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		if (i === list.length - 1){
			p[list[i]] = object;
		}else{
			p = p[list[i]];
		}
	}
}


const App = (): Node => {

	/* global BigInt */
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();
	const { address, isConnected } = useAccount();
	const { chain } = useNetwork();
	var chainId = BigInt(0);
	if (chain){
		chainId = BigInt(chain.id);
	}
	const [otherInfo, setOtherInfo] = React.useState({});
	const [_trackgallery, set_trackgallery] = React.useState(BigInt('0'));
	const [_stakinglist, set_stakinglist] = React.useState([]);
	const [_trackstaked, set_trackstaked] = React.useState(BigInt('0'));
	const [chainInfo, setChainInfo] = React.useState({});
	function clickActionfe_1__s_c0_8ae9a5d0_k_c0_8ec31f5e_i_c0_d427b68d_i_c3_f0b0e6bb(e){
		writeContract({ address:'0xdE70AeD3d14d39b4955147EfcF272334bdB75AB5', abi: YachtingVerse.abi, args:['0x3C470482066F2949Ecc745630250D50809cD0A27', BigInt( makeADecimalTextIntoLongText(5000 + '', Number(BigInt('18'))))], functionName: 'approve'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
	}
	function clickActionfe_1__s_c0_8ae9a5d0_k_c0_8ec31f5e_i_c0_d427b68d_i_c140_58e1ac42(e){
		writeContract({ address:'0x3C470482066F2949Ecc745630250D50809cD0A27', abi: Nft_YSTAKE.abi, args:[BigInt('1')], functionName: 'mint'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		const unwatch = watchContractEvent({address:'0x3C470482066F2949Ecc745630250D50809cD0A27', abi: Nft_YSTAKE.abi, eventName: 'ApprovalForAll'}, (log) => {
					for (var i12 = 0; i12 < log.length; i12++){
						var aLog = log[i12].args;
						if (((address ? address : '') === aLog.owner) && ('0x3C470482066F2949Ecc745630250D50809cD0A27' === aLog.operator)){
							unwatch(); 
		writeContract({ address:'0x3C470482066F2949Ecc745630250D50809cD0A27', abi: Nft_YSTAKE.abi, args:[BigInt('5000')], functionName: 'mint'}).then((aResponse)=>{ console.log(aResponse);}).catch((e1)=>{
		window.alert(e1)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);}); break;
						}
					}
				}
			);
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c0_66a5e871_i_c0_f4c1b647(gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc){
		return function(e){
		if(_stakinglist.includes(gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc)
		) {
		}else{
			set_stakinglist([..._stakinglist, gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc]); 
		};
		e.stopPropagation();
		}
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_59f0412b_i_c0_12da6cd2(e){
		set_trackgallery(((_trackgallery === BigInt('0')) ? BigInt('0') : (_trackgallery - BigInt('10')))); 
		e.stopPropagation();
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_59f0412b_i_c0_d9f40927_i_1_i_1(e){
		set_trackgallery((((_trackgallery + BigInt('10')) >= function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) ? _trackgallery : (_trackgallery + BigInt('10')))); 
		e.stopPropagation();
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c0_2c43d412_i_c0_64f1a844(e){
		writeContract({ address:'0x3C470482066F2949Ecc745630250D50809cD0A27', abi: Nft_YSTAKE.abi, args:['0x7eA6cA77695526781871D68CcE5ccB4856E238D5', isConnected], functionName: 'setApprovalForAll'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		const unwatch = watchContractEvent({address:'0x3C470482066F2949Ecc745630250D50809cD0A27', abi: Nft_YSTAKE.abi, eventName: 'ApprovalForAll'}, (log) => {
					for (var i12 = 0; i12 < log.length; i12++){
						var aLog = log[i12].args;
						if (((address ? address : '') === aLog.owner) && ('0x0cE24Bb340D585F70a78bFfFf340aa47849910fd' === aLog.operator)){
							unwatch(); 
		writeContract({ address:'0x7eA6cA77695526781871D68CcE5ccB4856E238D5', abi: Staking.abi, args:[_stakinglist], functionName: 'multipleStake'}).then((aResponse)=>{ console.log(aResponse);}).catch((e1)=>{
		window.alert(e1)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);}); break;
						}
					}
				}
			);
		e.stopPropagation();
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc_i_c0_66a5e871_i_c0_f4c1b647(gV_fe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc){
		return function(e){
		writeContract({ address:'0x7eA6cA77695526781871D68CcE5ccB4856E238D5', abi: Staking.abi, args:[gV_fe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc], functionName: 'unstake'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		e.stopPropagation();
		}
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_59f0412b_i_c0_12da6cd2(e){
		set_trackstaked(((_trackstaked === BigInt('0')) ? BigInt('0') : (_trackstaked - BigInt('10')))); 
		e.stopPropagation();
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_59f0412b_i_c0_d9f40927(e){
		set_trackstaked((((_trackstaked + BigInt('10')) >= function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) ? _trackstaked : (_trackstaked + BigInt('10')))); 
		e.stopPropagation();
	}
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c0_2c43d412_i_c66_73b6d98f(e){
		writeContract({ address:'0x7eA6cA77695526781871D68CcE5ccB4856E238D5', abi: Staking.abi, args:[], functionName: 'withdrawAllInterestWithoutUnstaking'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		e.stopPropagation();
	}
	function clickActioncn_c2_d1339868_nv_i_c1_3a5a4a1e(e){
		if (isConnected){
			if (chainId === BigInt(42161)){
				disconnect();
			}else{
				openChainModal();
			}
		}else{
			openConnectModal();
		}
		getInfo();
		
		e.stopPropagation();
	}
	function getInfo(){
		setChainInfo({}); 
	}
	function nav_c2_d1339868(){
		return (
		<nav className='navbar  navbar-light' style={{backgroundColor:'rgb(12, 25, 65)'}}>
			<span className='navbar-brand'><img src={'https://www.cues.sg/client_pictures/1090_hEjeoMPi.png'} style={{width:'250px'}} alt='logo' /><span ></span></span>
			
<button style={{color:'rgb(12, 25, 65)', fontFamily:'Zilla Slab', fontWeight:(1 ? 'bold' : 'normal'), cursor:'pointer'}} className='btn btn-light  '  onClick={clickActioncn_c2_d1339868_nv_i_c1_3a5a4a1e} >{(isConnected ? ((chainId === BigInt(42161)) ? shortenName((address ? address : '')) : ('Connect to the ' + 'Arbitrum One' + ' chain')) : 'CONNECT')}</button>
		</nav>)
	}
	var footer = <div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, backgroundColor:''}}>
				<div className='      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}><span key={0} style={{fontSize:'12px'}}><span style={{fontFamily:'consolas, "lucida console", "courier new", monospace'}}><span style={{color:'rgb(234, 235, 234)'}}>                                                                          © 2023 YachtingVerse All Rights Reserved.</span></span></span>
				</div>
			</div>
	return <div style={{color:'rgb(86, 86, 86)', fontFamily:'Poppins', backgroundColor:'rgb(12, 25, 65)'}}>{nav_c2_d1339868()}
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-end  align-items-start'>
					<div className='  col-12      text-center' style={{overflowWrap:false, borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{fontFamily:'Poppins'}}><span style={{color:'rgb(234, 235, 234)'}}><b>YachtingVerse Vault Pools 180 Days</b></span></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-end  align-items-start'>
					<div className='  col-12      text-center' style={{lineHeight:'2.67em', overflowWrap:false, borderWidth:0, padding:10}}><span key={0} style={{fontSize:'20px'}}><span style={{fontFamily:'Poppins'}}><span style={{color:'rgb(234, 235, 234)'}}>Mint 5000 $YACHT to join the Vault</span></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-2  mb-2  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<button style={{color:'rgb(12, 25, 65)', fontFamily:'Poppins', cursor:'pointer'}} className='btn btn-light    col-4    '  onClick={clickActionfe_1__s_c0_8ae9a5d0_k_c0_8ec31f5e_i_c0_d427b68d_i_c3_f0b0e6bb} >{'Approve'}</button>
					<div className='  col-3    '/>
					<button style={{color:'rgb(12, 25, 65)', fontFamily:'Poppins', cursor:'pointer'}} className='btn btn-light    col-4    '  onClick={clickActionfe_1__s_c0_8ae9a5d0_k_c0_8ec31f5e_i_c0_d427b68d_i_c140_58e1ac42} >{'Mint'}</button>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-2  mb-2  '/>
				</div></div>
			</div></div>
		</div>
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{overflowWrap:false, borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(234, 235, 234)'}}><b>Gallery</b></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}>
						<span key={0} style={{fontSize:'18px'}}>{(isConnected ? ((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) > BigInt('0')) ? 'Here are the NFTs that you can stake.' : 'You do not own any NFTs to stake') : 'Please log in to see the NFTs you have in your collection that can be staked.')}</span><br/>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					{listFromTwoUIntegers(_trackgallery, (((_trackgallery + BigInt('10')) >= function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) ? (function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) - BigInt('1')) : (_trackgallery + BigInt('9')))).map((_mapVar_c0_ac0426d2, index)=>{return function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'tokenOfOwnerByIndex', [{t:'Address'}, {t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), _mapVar_c0_ac0426d2, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []);}).map((gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, gVIndex)=>{ return (
						<div key={gVIndex} className='   col-6 col-md-4 col-xl-3'><div style={{backgroundColor:''}} className='      container-fluid'>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div  className='   col-12     '><img alt='generatedImage' src={cleanUpSrc(getInfoFromTokenURI(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'tokenURI', [{t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'Text String'}], []), otherInfo, setOtherInfo, 'image'))} style={{borderWidth:0, width:'100%'}}/></div>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<button style={{cursor:'pointer'}} className='btn btn-light    col-12    '  onClick={(handleClick)} >{'+Staking List'}</button>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
						</div></div>) })}
				</div></div>
				<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) >= BigInt('1')) ? false : true) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<button style={{cursor:'pointer'}} className='btn btn-light    col-3    '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_59f0412b_i_c0_12da6cd2} ><Icon height={'20px'} icon={'material-symbols:arrow-back-ios-rounded'} /> {''}</button>
					<div className='  col-2    '/>
					<div className='   col-3'><div style={{backgroundColor:''}} className='     '>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<button style={{cursor:'pointer'}} className='btn btn-light    col-12    '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_59f0412b_i_c0_d9f40927_i_1_i_1} ><Icon height={'20px'} icon={'material-symbols:arrow-forward-ios-rounded'} /> {''}</button>
						</div></div>
					</div></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-2  mb-2  '/>
				</div></div>
				<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) >= BigInt('1')) ? false : true) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<button style={{fontWeight:'bold', cursor:'pointer'}} className='btn btn-light    col-8    '  onClick={handleClick} >{'Stake!'}</button>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
			</div></div>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{overflowWrap:false, borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(234, 235, 234)'}}><b>Staked NFTs</b></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}>
						<span key={0} style={{fontSize:'18px', color:'rgb(234, 235, 234)'}}>{(isConnected ? ((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) > BigInt('0')) ? 'Here are the staked NFTs' : 'No NFTs currently staked.') : 'Please log in to see the NFTs that you have staked')}</span><br/>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					{listFromTwoUIntegers(_trackstaked, (((_trackstaked + BigInt('10')) >= function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) ? (function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) - BigInt('1')) : (_trackstaked + BigInt('9')))).map((_mapVar_c0_ac0426d2, index)=>{return function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'whichStakedTokenIDsOfAnAddress', [{t:'Address'}, {t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), _mapVar_c0_ac0426d2, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []);}).map((gV_fe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc, gVIndex)=>{ return (
						<div key={gVIndex} className='   col-6 col-md-4 col-xl-3'><div style={{backgroundColor:''}} className='      container-fluid'>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div  className='   col-12     '><img alt='generatedImage' src={cleanUpSrc(getInfoFromTokenURI(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3C470482066F2949Ecc745630250D50809cD0A27', Nft_YSTAKE.abi, 'tokenURI', [{t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], gV_fe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'Text String'}], []), otherInfo, setOtherInfo, 'image'))} style={{borderWidth:0, width:'100%'}}/></div>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<button style={{color:'rgb(12, 14, 12)', cursor:'pointer'}} className='btn btn-light    col-12    '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc_i_c0_66a5e871_i_c0_f4c1b647(gV_fe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_ac6bf424_i_c24_f349bedc)} >{'Unstake This'}</button>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
						</div></div>) })}
				</div></div>
				<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) >= BigInt('1')) ? false : true) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<button style={{cursor:'pointer'}} className='btn btn-light    col-3    '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_59f0412b_i_c0_12da6cd2} ><Icon height={'20px'} icon={'material-symbols:arrow-back-ios-rounded'} /> {''}</button>
					<div className='  col-2    '/>
					<button style={{cursor:'pointer'}} className='btn btn-light    col-3    '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c1_59f0412b_i_c0_d9f40927} ><Icon height={'20px'} icon={'material-symbols:arrow-forward-ios-rounded'} /> {''}</button>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-2  mb-2  '/>
				</div></div>
				<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x7eA6cA77695526781871D68CcE5ccB4856E238D5', Staking.abi, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) >= BigInt('1')) ? false : true) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<button style={{fontWeight:'bold', cursor:'pointer'}} className='btn btn-light    col-4    '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5720cf40_i_c0_2c43d412_i_c66_73b6d98f} >{'Claim Rewards'}</button>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
			</div></div>
		</div>{footer}</div>
}
function handleClick() {
    alert('Pool Filled!');
}
const App1 = () : Node => {

	var xx = {id:42161, name:'Arbitrum One', network:'arbitrum', nativeCurrency:{decimals:18, name:'ETH', symbol: 'ETH'}, rpcUrls:{default:{http:['https://1rpc.io/arb']}, public: {http:['https://1rpc.io/arb']}}, blockExplorers:{etherscan:{name:'Arbiscan',url:'https://arbiscan.io'}, default:{name:'Arbiscan', url:'https://arbiscan.io'}}, contracts:{ multicall3:{address:'0xca11bde05977b3631167028862be2a173976ca11', blockCreated:7654707}}}

	const { chains, publicClient, webSocketPublicClient } = configureChains(
		[xx],
		[publicProvider()]
	);

	const { connectors } = getDefaultWallets({
		appName: 'Stake180',
		projectId: '38a4550c014d0f6426307554b95a8e05',
		chains
	});

	const wagmiConfig = createConfig({
		autoConnect: true,
		connectors,
		publicClient,
		webSocketPublicClient,
	});

	return <WagmiConfig config={wagmiConfig}><RainbowKitProvider chains={chains}><App /></RainbowKitProvider></WagmiConfig>

	}

	

export default App1;